import React, { useEffect } from 'react';
import './MenuPage.css'; // 导入 CSS 文件
import menuBg from './image/menu_bg.jpg'; // 导入图片

const MenuPage = () => {
  useEffect(() => {
    document.title = "RFG Menu"; // 设置页面标题
  }, []);

  return (
    <div className="menu-page-container">
      <div className="content-container">
        <div className="top-section">
          {/* 左侧：图片 */}
          <div className="left-section">
            <img src={menuBg} alt="RFG Dish" className="menu-image" />
          </div>
          {/* 右侧：文字 */}
          <div className="right-section">
          <h1 className="heading">RFG Menus</h1>
            <p className="description">
              We are open for Lunch and Dinner,<br />
              everyday except Wednesday,<br />
              12:00pm – 21:30pm.<br /><br />
              For reservations of 6+, please contact us.
            </p>
          </div>
        </div>

        {/* 菜单部分 */}
        <div className="menu-section">
          <ul className="menu-list">
            <li>
              <a href="/garden-lunch.html" className="menu-link" target="_blank"><strong>Garden Lunch</strong></a>, designed for those seeking a quick, light yet flavorful meal.
            </li>
            <li>
              <a href="/raw-flame-grill.html" className="menu-link" target="_blank"><strong>RAW FLAME GRILL</strong></a>, showcases the heart of RFG Restaurant, where the finest New Zealand meats are prepared over an open flame.
            </li>
            <li>
              <a href="/september-harvest.html" className="menu-link" target="_blank"><strong>September Harvest</strong></a>, a set menu celebrating the best of New Zealand’s spring produce.
            </li>
            <li>
              <a href="/a-la.html" className="menu-link" target="_blank"><strong>À La Carte</strong></a>, an interception of classic French and modern techniques.
            </li>
            <li>
              <a href="/wine.html" className="menu-link" target="_blank"><strong>Wine List</strong></a>, a range of limited edition vintage wines. We welcome you to
              bring your own wine with a $20 corkage fee per bottle.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MenuPage;































